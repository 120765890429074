<template>
  <v-container class="container-card">
    <!-- <p class="display-1 text--primary">
      <v-icon class="mr-2">mdi-account-search</v-icon>Consulta Colaborador
    </p> -->
    <v-card>
      <TabelaColaboradores />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ConsultaColaborador",

  components: {
    TabelaColaboradores: () =>
      import("@/components/colaborador/TabelaColaboradores")
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Pessoa");
  }
};
</script>
